<template>
    <div class="rollcall" >
        <div class="nav">
            <van-row>
                <van-col span="4">
                    <div @click="back()">
                        <van-icon name="arrow-left" />
                    </div>
                </van-col>
                <van-col span="16">
                    {{ finished ? lang.personal_evaluation : lang.class_list_nav }} 
                </van-col>
                <van-col span="4">
                    <div @click="toggleLanguage">
                        <img class="lang-img" :src="lang.langBtn">
                    </div>
                </van-col>
            </van-row>
        </div>

        <div class="content">
            <van-row>
                <van-col offset="1" span="22">
                    <div class="row-str">{{lang.student}}</div>
                </van-col>
            </van-row>
            <div class="studentList"> 
                <van-row>
                    <template v-for="item in students" :key="item">
                        <van-col span="8" style="height:100%">
                            <div class="student" 
                                @click="chooseStudent(item)"
                                :class="getStateStyle(item)"
                            >{{item.name}}</div>
                        </van-col>
                    </template>
                </van-row>
            </div>
            <template v-if="currentStudent!=null">
                <template v-if="!finished">
                    <van-row>
                        <van-col offset="1" span="22">
                            <div class="row-str">{{lang.state}}</div>
                        </van-col>
                    </van-row>
                    <van-row>
                        <van-col span="8">
                            <div class="ontimeBtn" 
                                @click="setCurrentState(currentStudent,consts.state.ontime)"
                                :class="currentStudent.state == consts.state.ontime ? consts.state.ontime : '' " 
                                >{{lang.on_time}}</div>
                        </van-col>
                        <van-col span="8">
                            <div class="ontimeBtn"
                                @click="setCurrentState(currentStudent,consts.state.late)"
                                :class="currentStudent.state == consts.state.late ? consts.state.late : '' " 
                                >{{lang.late}}</div>
                        </van-col>
                        <van-col span="8">
                            <div class="ontimeBtn"
                                @click="setCurrentState(currentStudent,consts.state.leave)"
                                :class="currentStudent.state == consts.state.leave ? consts.state.leave : '' " 
                                >{{lang.leave}}</div>
                        </van-col>
                        <van-col span="8">
                            <div class="ontimeBtn"
                                @click="setCurrentState(currentStudent,consts.state.absence)"
                                :class="currentStudent.state == consts.state.absence ? consts.state.absence : '' " 
                                >{{lang.absence}}</div>
                        </van-col>
                        <van-col span="8">
                            <div class="ontimeBtn"
                                @click="setCurrentState(currentStudent,consts.state.unjoin)"
                                :class="currentStudent.state == consts.state.unjoin ? consts.state.unjoin : '' " 
                                >{{lang.unjoin}}</div>
                        </van-col>
                    </van-row>
                </template>
                <template v-else>
                    <van-row>
                        <van-col offset="1" span="22">
                            <div class="row-str">{{lang.remarks}}</div>
                        </van-col>
                    </van-row>
                    <van-row>
                        <van-col offset="1" span="22" v-if="currentStudent.info">
                            <textarea 
                                rows="8" 
                                v-model="currentStudent.info" 
                                class="comments" 
                                :placeholder="lang.comments"
                                readonly
                            ></textarea>
                        </van-col>
                        <van-col offset="2" span="20"  class="left" style="margin-top:20px;" v-if="currentStudent.images">
                            <van-uploader 
                                class="upload"
                                accept='image'
                                v-model="currentStudent.images"
                                :disabled="false"
                                :deletable="false"
                                :show-upload="false"
                                max-count="9" 
                                image-fit="contain"
                                multiple />
                        </van-col>
                        <van-col offset="2" span="20" style="margin-top:20px;" v-if="currentStudent.files">
                            <div class="uploadJob file" v-for="file in currentStudent.files" :key="file">
                                <van-row>
                                    <van-col offset="1" span="20">
                                        <div class="fileName">{{file.name}}</div>
                                    </van-col>
                                </van-row>
                            </div>
                        </van-col>
                    </van-row>
                </template>
            </template>
        </div>

        <div class="foot" v-if="!finished">
            <van-row>
                <van-col offset="1" span="21">
                    <div class="form-button" @click="submit">{{lang.finishClassAndSubmit}}</div>
                </van-col>
            </van-row>
        </div>

        <div class="foot" v-if="finished && currentStudent!=null"><!--个人评论-->
            <van-row>
                <van-col offset="1" span="21">
                    <div class="form-button" @click="PersonalEvaluation">{{lang.evaluateStudents}}</div>
                </van-col>
            </van-row>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs,onMounted } from 'vue';
import Common from "../../library/Common";
import Consts from "../../library/Consts";
import request from '../../library/MyRequest';
import { useRouter } from 'vue-router';
import { Dialog, Toast } from 'vant';
import "@/css/form.css";

export default {
  components: {  },
    name: 'rollcall',
    setup(){
        const consts = Consts;
        const common = Common;
        const router = useRouter();
		common.chechLogin(router);
		const lesson_id = router.currentRoute.value.params.id;
        const student_id = router.currentRoute.value.params.student_id || null;

        let state = reactive({
            lang:{
                class_list_nav:"",
                roll_call:"",
                student:"",
                state:"",
                on_time:"",
                late:"",
                absence:"",
                leave:"",
                unjoin:"",
                langBtn:"",
                remarks:"",
                comments:"",
                submit:"",
                finishClassAndSubmit:"",
                Itcannotbemodifiedaftersubmission:"",
                yes:"",
                no:"",
                submitSuccess:"",
                personal_evaluation:"",
                notfilledIn:"",
                confirm:"",
                networkError:"",
                uploading:"",
                evaluateStudents:"",
            },
            students:[],
            currentStudent:null,
            finished:false,
        });
        common.initLanguage(state.lang);
        state.lang.langBtn = common.getLanguageBtn();
        const toggleLanguage = function(){
            common.toggleLanguage();
            common.initLanguage(state.lang);
            state.lang.langBtn = common.getLanguageBtn();
        }
        
		const back = function(){
            router.push({name: 'LessonList'});
        }
		
        const setCurrentState = function(currentStudent,state){
            currentStudent.state = state;
        }
        
        const getLesson = async()=>{
            var res = await request.get(`/lesson/attendance/?lesson_id=${lesson_id}`);
            state.finished = res.data.finished;
            state.students = res.data.students.map((item)=>{
                return {
                    id:item[1],
                    name:item[0],
                    state:item[2],
                }
            });
            if(student_id){
                state.currentStudent = state.students.find((item)=>{
                    return item.id == student_id;
                });
                getFeedback(state.currentStudent);
            }
        }

        const getStateStyle = function(student){
            var result = student.state;
            if (student == state.currentStudent){
                result += " on";
            }
            return result;
        }

        const chooseStudent = async(student)=>{
            if(state.finished && (!student.state || student.state == "absence"))
                return ;
            if(state.currentStudent == null){
                state.currentStudent = student;
                await getFeedback(student);
            }else if(state.currentStudent.id == student.id){
                state.currentStudent = null;
            }else{
                state.currentStudent = student;
                await getFeedback(student);
            }
        }

        const getFeedback = async(student)=>{
            if(!state.finished){
                return ;
            }
            if(!student.hasOwnProperty("info")){
                Toast.loading({
                    forbidClick: true,
                    overlay:true,
                });
                student.info ="";
                student.images =[];
                student.files =[];
                try{
                    var res = await request.get(`lesson/feedback-personal/?lesson_id=${lesson_id}&admission_no=${student.id}&force=1`);
                    if(res.status == 200){
                        let data = res.data;
                        student.info = data.info;
                        student.files = data.files;
                        student.images = data.images.map((item)=>{return {url:item} ;});
                    }
                }catch(e){
                    console.log(e);
                }
                 Toast.clear();
            }
        }

        const submit = async()=>{
            var emptyStatusStudents = state.students.filter((item)=>{return item.state == null;});
            if(emptyStatusStudents.length > 0){
                Dialog.alert({
                    message: state.lang.notfilledIn,
                    confirmButtonText:state.lang.confirm,
                });
                return ;
            }
            Dialog.confirm({
                message:state.lang.Itcannotbemodifiedaftersubmission,
                confirmButtonText:state.lang.yes,
                cancelButtonText:state.lang.no,
            })
            .then(async() => {
                sendData();
            });
        }
        
        const sendData = async()=>{
            var students = state.students.map((item)=>{
                return {
                    id:item.id,
                    state:item.state,
                    info:item.info ? item.info : "",
                };
            });
            
            var send = {
                lesson_id:lesson_id,
                students:students,
            }
            try{   
                var res = await request.post(`lesson/create-attendance/`,send);
                if(res.status == 200){
                    Toast({
                        forbidClick:true,
                        message:state.lang.submitSuccess,
                    });
                }else if(res.status == 201){
                    Toast({
                        forbidClick:true,
                        message:state.lang.submitSuccess,
                        onClose:()=>{
                            back();
                        }
                    });
                }
            }catch(e){
                Toast(state.lang.networkError);
                console.log(e);
            }
            
        }


        const PersonalEvaluation = function(){
            let params = {
                lesson_id:lesson_id,
                student_id:state.currentStudent.id,
                student_name:state.currentStudent.name,
            }
            router.push({name: 'PersonalEvaluation',params:params});
        }
        
        onMounted(async()=>{
            if(!lesson_id){
                back();
            }else{
                await getLesson();
            }
        });

        return {
            ...toRefs(state),
            common,
            toggleLanguage,
            back,
            getStateStyle,
            setCurrentState,
            chooseStudent,
            submit,
            consts,
            sendData,
            PersonalEvaluation
        };
    }
}
</script>

<style scoped>
.rollcall{
   width: 100%;
   height:100%; 
   background:#F6F6F6;
}
.student{
    height: 100%;
}
.student,.ontimeBtn{
    font-size:32px;
    color:333;
    border-radius: 10px;
    background-color: #fff;
    padding:34px 30px;
    text-align: center;
    vertical-align:middle;
    margin: 0 10px 22px 10px;
    font-weight: 600;
}

.ontimeBtn{
    background-color: #F6F6F6;
    border:1px solid #333333;
    white-space:nowrap;
}

.on{
    border:3px solid #333333;
}

.late{
    background-color: #F47F31;
    color:#fff;
}

.ontime{
    background-color: #A38F7D;
    color:#fff;
}

.leave{
    background-color: #3399CC;
    color:#fff;
}

.absence{
    background-color: #ccc;
    color:#ff0000;
}

.unjoin{
    background-color: #999;
    color:#eee;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.content{
    height:calc(100% - 250px);
    overflow-y:auto;
    overflow-x:hidden;
}
.block {
    width: 120px;
    height: 120px;
    background-color: #fff;
}

.comments{
    width: 90%;
    border-radius: 10px;
    border:1px solid #919191;
    font-size:28px;
    resize: none;
    padding:20px;
    border:0px;
}

.foot{
    position:fixed;
    width: 100%;
    bottom:0px;
    background-color: #fff;
    font-size: 28px;
    padding:30px 0;
}



.uploadJob{
    width: 100%;
    height: 100px;
    line-height: 100px;
    border:5px solid #4284FD;
    border-radius: 20px;
    color: #4284FD;
    font-size: 32px;
    font-weight: 600;
    margin-top:20px;

}

.file{
    border:5px solid green;
    color: green;
}

.fileName{
    width: 100%;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
}
</style>